header {
    height: 100vh;
    padding-top: 1rem;
    overflow: hidden;
}

.header_container { 
    text-align: center;
    height: 100%;
    position: relative;
}


/* ============================= CTA ==================================== */

.cta {
    margin-top: 2.5rem;
    display: flex;
    justify-content: center;
}

/* ============================= header socials ==================================== */

.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}

/* no need for this */
.header__socials::after {
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}


/* ============================= picture ==================================== */

.me {
    background: linear-gradient(var(--color-primary),transparent);
    width: 18rem;
    height: 35rem;
    position: absolute;
    left: calc(50% - 9rem);
    margin-top: 3rem;
    border-radius: 12rem 12rem 0 0;
}


.me img {
    margin: 2rem 0rem;
}


/* ============================= SCROLL DOWN ==================================== */
.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}

/* ============================== MEDIA QUEIRES (MEDIUM DEVICES) =================================== */

@media screen and (max-width: 1024px) {
    header {
        height: 100vh;
        overflow: hidden;
    }

    .me {
        background: linear-gradient(var(--color-primary),transparent);
        width: 15rem;
        height: 27rem;
        left: calc(50% - 7.5rem);
    }

    .me img {
        width: 100%;
        height:auto;
        margin-top: 1rem;
    }
}

/* ============================== MEDIA QUEIRES (SMALL DEVICES) =================================== */

@media screen and (max-width: 600px) {
    header {
        height: 100vh;
    }

    .header__socials, .scroll__down {
        display: none;

    }
}