.projects__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
    /* align-items: start; */
}

.project {
    background: var(--color-bg-variant);
    border-radius: 0 0 2rem 2rem;
    border: 0px solid var(--color-primary);
    /* height: fit-content; */
    transition: var(--transition);
    padding: 0rem 0 3rem;
}

.project:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}


.project__head {
    background: var(--color-primary);
    padding: 2rem;
    border-radius: 0 0 2rem 2rem;
    box-shadow: 0 2rem 1rem rgba(0, 0, 0, 0.1);
}

.project__head h3 {
    color: var(--color-bg);
    font-size: 1rem;
    text-align: center;
}

.project__list {
    padding: 1.5rem 1rem 2rem;
}

.project__list li {
    display: flex;
    gap: 1rem;
    margin-bottom: 0.8rem;
}

.project__list-icon {
    height: 40px;
    width: 40px;
    color: var(--color-primary);
}

.project__list p {
    font-size: 0.9rem;
    padding-right: 0.2rem;

}


/* ============================== MEDIA QUEIRES (MEDIUM DEVICES) =================================== */

@media screen and (max-width: 1024px) {
    .projects__container {
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }

    .project {
        height: auto;
    }
}

/* ============================== MEDIA QUEIRES (SMALL DEVICES) =================================== */

@media screen and (max-width: 600px) {
    .projects__container {
        grid-template-columns: 1fr;
        gap: 1.5rem;
    }
}